import React from 'react';
import { Link } from 'react-router-dom';
import { removeCartItem } from '../../../redux/Cart/cart.actions';
import { useDispatch } from 'react-redux';
// import $ from 'jquery'

export default function CartListItem({ item, value }) {
  const dispatch = useDispatch();
  const { _id, desc, title, price, quantity, img, documentID } = item;
  const handleRemoveCartItem = documentID => {
    dispatch(
      removeCartItem({
        documentID
      })
    );
  };
  return (
    <tr>
      <td className='product-image'>
        <Link to='/product-details' onClick={() => value.handleDetails(_id)}>
          <img src={img} alt='Cart' />
        </Link>
      </td>
      <td className='product-description'>
        {title}
        <p>{desc}</p>
      </td>
      <td className='product-price text-center'>₺{price}</td>
      <td className='product-quantity text-center'>
        <div className='product-details-quantity'>
          <span type='number' className='btn btn-black mx-1'>
            {quantity}
          </span>
        </div>
      </td>
      <td className='product-total'>{price}</td>
      <td className='product-remove text-center'>
        <Link to='#' onClick={() => handleRemoveCartItem(_id)}>
          x
        </Link>
      </td>
    </tr>
  );
}
