import React, { useState } from "react";
import { FaGoogle, FaFacebookF, FaTwitter } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import authService from "../../fetchServices/AuthService";
import { message } from "antd";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function SignUpForm() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("İsim ve Soyisim zorunludur"),
    email: Yup.string()
      .required("Email Zorunludur.")
      .email("Email Zorunludur."),
    password: Yup.string()
      .min(6, "Şifreniz en az 6 karakterli olmalıdır")
      .required("Şifre gereklidir"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Şifreler Uyumlu Değil")
      .required("Şifre gereklidir"),
    privacy: Yup.bool().oneOf(
      [true],
      "Gizlilik Sözleşmesi Onaylanması Zorunludur"
    ),
    kvkk: Yup.bool().oneOf(
      [true],
      "Kişisel Verilerin Korunması Sözleşmesi Onaylanması Zorunludur"
    ),
    phone: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Geçersiz Numara"
      )
      .min(10, "Telefon Numarası 10 Haneli olmalıdır")
      .max(10, "Telefon Numarası 10 Haneli olmalıdır")
      .required("Telefon Numarası gereklidir"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const user = {
      username: data.name,
      name: data.name,
      password: data.password,
      email: data.email,
      phone: data.phone,
      kvkk: data.kvkk,
      privacy: data.privacy,
      contact: data.sms,
    };
    authService
      .crete(user)
      .then((response) => {
        message.success("Başarıyla Kayıt Oldunuz!");
        onOpenModal();
      })
      .catch((err) => {
        message.error("Bir hata oluştu !");
      });
    return false;
  }

  return (
    <section className="signup-form-wrapper">
      <div className="container">
        <div className="signup-form-box">
          <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
            <input
              placeholder="Adınız"
              name="name"
              type="text"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>

            <input
              placeholder="Mail Adresiniz"
              name="email"
              type="text"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>

            <input
              name="password"
              type="password"
              placeholder="Şifre"
              {...register("password")}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
            <input
              name="confirmPassword"
              type="password"
              placeholder="Şifre Tekrarı"
              {...register("confirmPassword")}
              className={`form-control ${
                errors.confirmPassword ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>

            <input
              name="phone"
              type="number"
              placeholder="Telefon Numaranızı başında 0 olmadan giriniz"
              {...register("phone")}
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.phone?.message}</div>
            <div className="checkboxes">
              <label className="privacy-policy">
                <Link to="/privacy" target="_blank">
                  <a style={{ textDecorationLine: "underline" }}>
                    {" "}
                    Gizlilik Sözleşmesi'ni
                  </a>
                </Link>
                okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz
                <input
                  name="privacy"
                  type="checkbox"
                  {...register("privacy")}
                  id="privacy"
                  className={`form-check-input ${
                    errors.privacy ? "is-invalid" : ""
                  }`}
                />
                <span className="checkmark"></span>
              </label>
              <div className="invalid-feedback">{errors.privacy?.message}</div>
              <label className="privacy-policy">
                <Link to="/kvkk" target="_blank">
                  <a style={{ textDecorationLine: "underline" }}>
                    Kişisel Verilerin Korunması'nı
                  </a>
                </Link>
                okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz{" "}
                <input
                  name="kvkk"
                  type="checkbox"
                  {...register("kvkk")}
                  id="kvkk"
                  className={`form-check-input ${
                    errors.kvkk ? "is-invalid" : ""
                  }`}
                />
                <span className="checkmark"></span>
              </label>
              <div className="invalid-feedback">{errors.kvkk?.message}</div>
              <label className="privacy-policy">
                Erya Groups tarafından ürün, hizmet ve kampanyalarla ilgili
                reklam, pazarlama ve bilgi amaçlı her türlü ticari elektronik
                ileti gönderilmesine ve diğer iletişim araçları kullanılarak
                tarafımla iletişime geçilmesine izin veriyorum
                <input
                  name="sms"
                  type="checkbox"
                  {...register("sms")}
                  id="sms"
                  className={`form-check-input ${
                    errors.sms ? "is-invalid" : ""
                  }`}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <button
              type="submit"
              value="Register Account"
              className="theme-button"
            >
              Kayıt Ol <FiChevronRight className="icon" />
            </button>
          </form>
          <p className="already-account">
            Hesabınız Var mı? <Link to="/login">Giriş Yap</Link>
          </p>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div>
          <h2>Başarıyla Kayıt Oldunuz!</h2>
        </div>
      </Modal>
    </section>
  );
}
