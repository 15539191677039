import React, { useEffect, useState } from 'react';
import Navbar from '../components/common/Navbar';
import SectionsTitle from '../components/common/SectionsTitle';
import About1 from '../components/about/About1';
import Divider from '../components/other/Divider';
import About from '../components/about/About';
import CounterUp from '../sections/CounterUp';
import HeroSlider from '../components/sliders/HeroSlider';
import SimpleTab from '../components/tabs/SimpleTab';
import Cta from '../sections/Cta';
import Team from '../components/team/Team';
import ChartArea from '../sections/ChartArea';
import Clients from '../sections/Clients';
import Pricing from '../sections/Pricing';
import CtaForm from '../components/other/CtaForm';
import Blog from '../components/blog/Blog';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiRefreshCcw } from 'react-icons/fi';
import Footer from '../components/other/Footer';
import sectiondata from '../store/store';
import ServiceSection1 from '../sections/services/ServiceSection1';
import TestimonialSection from '../sections/TestimonialSections';
import BestProducts from '../components/shop/BestProducts';
import productService from '../fetchServices/ProductService';
import FullPageLoader from '../components/other/Loader';
import Breadcrumb from "../components/common/Breadcrumb";

export default function Service() {
  const [product, setProduct] = useState();
  const [reference, setReference] = useState();
  const [loading, setLoading] = useState();
  const getHome = () => {
    setLoading(true);
    productService
      .homeGet()
      .then(response => {
        setProduct(response.product);
        const referenceImg = response.reference.filter(x => x.image);
        setReference(referenceImg);
        setLoading(false);
        //   setUser(response);
        //  message.success('Kullanıcı Listesi Hazır.');
      })
      .catch(error => {
        //message.error('Bir Hata Oluştu. Sistem yöneticiyle irtibata geçin.');
      });
  };

  useEffect(() => {
    getHome();
  }, []);
  if (loading) return <FullPageLoader loading={loading} />;
  return (
    <>
      {/* Header */}
      <header className='header-area'>
        <Navbar />
      </header>

      {/* Services */}
      <Breadcrumb title="Hizmetlerimiz" />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <section className='shop-best-products-wrapper text-center'>
        <SectionsTitle title='Hizmet Paketlerimiz' />
        <BestProducts product={product} />
      </section>
      <Footer />
    </>
  );
}
