import { FiChevronRight } from "react-icons/fi";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import contactService from "../../fetchServices/ContactService";
import { message } from "antd";
import "./footterForm.css"
export default function FooterForm() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("İsim ve Soyisim zorunludur"),
    subject: Yup.string().required("Konu Zorunludur."),
    email: Yup.string()
      .required("Email Zorunludur.")
      .email("Email Zorunludur."),
    phone: Yup.string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Geçersiz Numara"
      )
      .min(10, "Telefon Numarası 10 Haneli olmalıdır")
      .max(10, "Telefon Numarası 10 Haneli olmalıdır")
      .required("Telefon Numarası gereklidir"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const params = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
    };
    contactService
      .create(params)
      .then((response) => {
        message.success(
          "Bilgileriniz Bize Ulaştı ! En Yakın Zaman da Uzman Bir Ekip Arkadaşımız Sizi Arayacak."
        );
      })
      .catch((err) => {
        message.error("Beklenmeyen Bir Hata Oluştu");
      });
    return false;
  }

  return (
    <div className="footer-widget footer-get-in-touch">
      <h2>Sizi Arayalım.</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="get-in-touch">
        <div className="form-grid">
          <input
            placeholder="Adınız Soyadınız"
            name="name"
            type="text"
            {...register("name")}
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.name?.message}</div>
          <input
            placeholder="Mail Adresiniz"
            name="email"
            type="text"
            {...register("email")}
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>

          <input
            name="phone"
            type="number"
            placeholder="Telefon Numaranızı başında 0 olmadan giriniz"
            {...register("phone")}
            className={`form-control ${errors.phone ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.phone?.message}</div>

          <input
            placeholder="Konu"
            name="subject"
            type="text"
            {...register("subject")}
            className={`form-control ${errors.subject ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.subject?.message}</div>
        </div>

        <p>
          * Formu göndermeniz halinde tarafınıza yazılı ya da sözlü iletişime
          geçilmesi için onay vermiş sayılırsınız.
        </p>
        <p />
        <button type="submit" value="call-me" className="theme-button">
          Beni Arayın
        </button>
      </form>
    </div>
  );
}
