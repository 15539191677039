import fetch from '../config/FetchInterceptor';

const contactService = {};

contactService.create = function (params) {
  return fetch({
    url: `/api/contact`,
    method: 'post',
    data: params,
    headers: {
      'public-request': 'true'
    },
  });
};
export default contactService;
