import React, { useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import HomeV2 from './pages/HomeV2';
import ShopHomePage from './pages/ShopHomePage';
import Shop from './pages/Shop';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import About_Page from './pages/About_Page';
import TeamGrid from './pages/TeamGrid';
import TeamDetails from './pages/TeamDetails';
import TestimonialPage from './pages/TestimonialPage';
import FAQ from './pages/FAQ';
import OurPricing from './pages/PricingPage';
import SignUp from './pages/SignUpPage';
import Login from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import ServicePage from './pages/ServicePage';
import ServiceDetailsPage from './pages/ServiceDetailsPage';
import Portfolio from './pages/PortfolioPage';
import PortfolioDetailsPage from './pages/PortfolioDetailsPage';
import BlogFullPage from './pages/BlogFullPage';
import BlogGridPage from './pages/BlogGridPage';
import BlogDetailsPage from './pages/BlogDetailsPage';
import ContactPage from './pages/ContactPage';
import Error from './pages/Error';
import Contentment from './pages/Contentment';
import './assets/css/style.css';
import './App.css';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SalesContract from './pages/SalesContract';
import Kvkk from './pages/Kvkk';
import Confirm from './pages/Confirm';
import 'antd/dist/antd.css';
import Profile_Page from './pages/Profile';
import PasswordChange from './pages/PasswordChange';
import OrderPage from './pages/Order';
import NoPayment from './pages/NoPayment';
import PrivateRoute from './pages/PrivateRoute';
import Service from './pages/Service';
import SuccessPayment from './pages/SuccessPayment';
function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/services' component={Service} />
          <Route path='/product-details' component={ProductDetailsPage} />
          <Route path='/cart' component={CartPage} />
          <Route path='/checkout' component={CheckoutPage} />
          <Route path='/about' component={About_Page} />
          <Route path='/contentment' component={Contentment} />
          <Route path='/testimonial' component={TestimonialPage} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/login' component={Login} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/services' component={ServicePage} />
          <Route path='/contact' component={ContactPage} />
          <Route path='/privacy' component={PrivacyPolicy} />
          <Route path='/sales-contract' component={SalesContract} />
          <Route path='/kvkk' component={Kvkk} />
          <Route path='/confirm/:confirmationCode' component={Confirm} />
          <PrivateRoute path='/paymenterror' component={NoPayment} />
          <PrivateRoute path='/paymentsuccess' component={SuccessPayment} />
          <PrivateRoute path='/profile' component={Profile_Page} />
          <PrivateRoute path='/changePassword' component={PasswordChange} />
          <PrivateRoute path='/order' component={OrderPage} />
          <Route component={Error} />
          {/*
          <Route path='/home-v2' component={HomeV2} />
          <Route path='/shop-home' component={ShopHomePage} />
          <Route path='/shop' component={Shop} />
          <Route path='/service-details' component={ServiceDetailsPage} />
          <Route path='/portfolio-grid' component={Portfolio} />
          <Route path='/portfolio-details' component={PortfolioDetailsPage} />
          <Route path='/blog-full-width' component={BlogFullPage} />
          <Route path='/blog-grid' component={BlogGridPage} />
          <Route path='/blog-details' component={BlogDetailsPage} />
          <Route path='/faq' component={FAQ} />
          <Route path='/our-pricing' component={OurPricing} />
          
          <Route path='/team-grid' component={TeamGrid} />
        <TawkMessengerReact propertyId='623ae5b75a88d50db1a6e757' widgetId='1fur12lf3' />
          <Route path='/team-details' component={TeamDetails} />*/}
        </Switch>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
<a href="https://api.whatsapp.com/send?phone=+9005449140094&text=%20EĞİTİM%20HAKKINDA%20BİLGİ%20ALMAK%20İSTİYORUM" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>
      </Route>
    </React.Fragment>
  );
}

export default App;
