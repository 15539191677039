import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronsLeft } from 'react-icons/fi';
import sectiondata from '../../store/store';

export default function PaymentContent() {
  return (
    <section className='error-content-wrapper text-center'>
      <div className='error-content-width'>
        {sectiondata.paymentdata.img ? (
          <img className='' src={sectiondata.paymentdata.img} alt='Error' />
        ) : (
          ''
        )}
        {sectiondata.paymentdata.title ? <h1>{sectiondata.paymentdata.title}</h1> : ''}
        {sectiondata.paymentdata.content ? <p>{sectiondata.paymentdata.content}</p> : ''}
        {sectiondata.paymentdata.connector ? (
          <div className='connector'>
            <span>{sectiondata.paymentdata.connector}</span>
          </div>
        ) : (
          ''
        )}
        {sectiondata.paymentdata.backbtn ? (
          <Link className='theme-button' to='/login'>
            <FiChevronsLeft className='icon' /> {sectiondata.paymentdata.backbtn}
          </Link>
        ) : (
          ''
        )}
      </div>
    </section>
  );
}
