import React from 'react';
import { Link } from 'react-router-dom';
import { removeCartItem } from '../../../redux/Cart/cart.actions';
import { useDispatch } from 'react-redux';
export default function MenuCartListItem({ item, value }) {
  const dispatch = useDispatch();
  const {
    id,
    name,
    price,
    quantity,
    image,
    discountPrice,
    documentID
  } = item;

  const handleRemoveCartItem = documentID => {
    dispatch(
      removeCartItem({
        documentID
      })
    );
  };
  return (
    <div className='items'>
      <img src={image?.url} alt='Product' />
      <div className='item__info'>
        <Link to='/product-details' onClick={() => value.handleDetails(id)}>
          {name}
        </Link>
        {quantity} x {price ? <del className='del item__info-price'>₺{price}</del> : ''}{' '}
        <span className='item__info-price'>₺{discountPrice}</span>
      </div>
      <Link to='#' className='item__remove' onClick={() => handleRemoveCartItem(documentID)}>
        x
      </Link>
    </div>
  );
}
