import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProductConsumer } from '../../productcontext';
import { FiChevronRight, FiHeart, FiShoppingBag, FiCheck } from 'react-icons/fi';
import { FaRandom } from 'react-icons/fa';
import { MdAddShoppingCart, MdRemoveShoppingCart } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, removeCartItem, reduceCartItem } from './../../redux/Cart/cart.actions';
const mapState = state => ({
  cart: state.cartData.cartItems
});
const BestProductContent = props => {
  const { _id, title, discountPrice, price, sales, buttontext, img, stars } = props.product;
  const dispatch = useDispatch();
  const { cart } = useSelector(mapState);
  const handleAddToCart = product => {
    if (!product) return;
    dispatch(addProduct(product));
  };
  const handleRemoveCartItem = documentID => {
    console.log('documentID : ', documentID);
    dispatch(
      removeCartItem({
        documentID
      })
    );
  };
  const inCart = cart?.filter(x => x._id === _id);
  return (
    <div className='product-item text-center'>
      <div className='product-img' onClick={() => console.log('dddd: ')}>
        <span className={`onsale ${sales || 'd-none'}`}>{sales}</span>
        <img src={img} className='product__img' alt='Shop' />
      </div>

      <div className='product-content'>
        <h3 className='product__title'>
          <Link
            onClick={() =>
              localStorage.setItem('SELECTED_ITEM', JSON.stringify(props.product))
            }
            to={{ pathname: `/product-details/` }}
          >
            {title}
          </Link>
        </h3>

        <div className='prices'>
          {discountPrice ? (
            <span className={`current__price del ${discountPrice || 'd-none'}`}>
              TL {discountPrice}
            </span>
          ) : (
            ' '
          )}
          <span className='current__price ins'>TL {price}</span>
        </div>
      </div>
      <div className='addto-cart-btn'>
        {inCart?.length > 0 ? (
          <button
            type='submit'
            className='theme-button'
            // disabled={inCart ? true : false}
            onClick={() => handleRemoveCartItem(_id)}
          >
            <>
              Sepeten Çıkar <MdRemoveShoppingCart className='icon' />
            </>
          </button>
        ) : (
          <button
            type='submit'
            className='theme-button'
            // disabled={inCart ? true : false}
            onClick={() => handleAddToCart(props.product)}
          >
            <>
              Sepete Ekle <MdAddShoppingCart className='icon' />
            </>
          </button>
        )}
      </div>
    </div>
  );
};

export default BestProductContent;

BestProductContent.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.number,
    img: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    sales: PropTypes.string,
    buttontext: PropTypes.string,
    discountPrice: PropTypes.number,
    price: PropTypes.number
  }).isRequired
};
