import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import SectionsTitle from "../components/common/SectionsTitle";
import Breadcrumb from "../components/common/Breadcrumb";
import ProductDetailsItem from "../components/shop/ProductDetailsItem";
import Divider from "../components/other/Divider";
import RelatedProducts from "../components/shop/RelatedProducts";
import Footer from "../components/other/Footer";
import { useLocation } from "react-router-dom";
import FullPageLoader from "../components/other/Loader";
import productService from "../fetchServices/ProductService";
import { FiChevronRight, FiChevronDown, FiCheck } from "react-icons/fi";
import $ from "jquery";
import { MdAddShoppingCart, MdRemoveShoppingCart } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  removeCartItem,
  reduceCartItem,
} from "../redux/Cart/cart.actions";

const mapState = (state) => ({
  cart: state.cartData.cartItems,
});

export default function ProductDetailsPage() {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);

  const _product = localStorage.getItem("SELECTED_ITEM");
  const id = JSON.parse(_product);
  const getProduct = async () => {
    setLoading(true);
    await productService
      .find(id?._id)
      .then((response) => {
        setLoading(false);
        setProduct(response);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getProduct();
  }, []);

  const dispatch = useDispatch();
  const { cart } = useSelector(mapState);
  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(addProduct(product));
  };
  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID,
      })
    );
  };

  if (loading) return <FullPageLoader loading={loading} />;
  if (!product) return null;
  const { _id, title, price, img, desc, discountPrice, buttontext } = product;
  const inCart = cart?.filter((x) => x._id === _id);
  return (
    <>
      <header className="header-area">
        <Navbar />
      </header>
      <Breadcrumb title="Ürün Detay" />
      <section className="product-details-item-wrapper">
        <div className="container">
          <div className="product-details-item">
            <div className="row">
              <div className="col-5 product-details-item-left">
                <img src={img} alt="Product Details" />
              </div>
              <div className="col-7 product-details-item-right">
                <div className="product-details-item-content">
                  <h1>{title}</h1>

                  <div className="amount">
                    {discountPrice ? <del>TL {discountPrice}</del> : ""}
                    <ins>TL{price}</ins>
                  </div>
                  <div className="html-view" dangerouslySetInnerHTML={{ __html: desc }} />
                  <div className="product-details-quantity-flex d-flex">
                    <div className="product-details-button">
                      {inCart?.length > 0 ? (
                        <button
                          type="submit"
                          className="theme-button"
                          // disabled={inCart ? true : false}
                          onClick={() => handleRemoveCartItem(_id)}
                        >
                          <>
                            Sepeten Çıkar{" "}
                            <MdRemoveShoppingCart className="icon" />
                          </>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="theme-button"
                          // disabled={inCart ? true : false}
                          onClick={() => handleAddToCart(product)}
                        >
                          <>
                            Sepete Ekle <MdAddShoppingCart className="icon" />
                          </>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
